import {
    AutoRule,
    BoltPattern,
    CompoundRule,
    FractionalNumberRule,
    InListRule,
    IsSingleRule,
    LedSize,
    NumberRangeRule,
    NumberRule,
    NumberWithDigitsRule,
    RequiredRule,
    StringRule,
    SubmodelRule,
    VerifyRule,
    WholeNumberRule
} from "./Rule";
import {endsWith, find, groupBy, isEmpty, last, parseInt, startsWith} from "lodash";
import {createRange, NumberRange} from "../../util/util";
import {Row} from "read-excel-file";

export type RuleType =
    'Required' |
    'Число' |
    'Число(?)' |
    'Дробное число' |
    'Целое число' |
    'Диапазон чисел(?)' |
    'In List' |
    'Is Single' |
    'Разболтовка' |
    'Строка' |
    'auto' |
    'Sub model' |
    'Размер фары';

export type SubModelType =
    'Sub model' |
    'Sub model1' |
    'Sub model2' |
    'Sub model3' |
    'Sub model4' |
    'Sub model5' |
    'Sub model6' |
    'Sub model7' |
    'Sub model8' |
    'Sub model9';

type ClassList = {
    [key in RuleType]: typeof VerifyRule;
}
export type Presets = {
    [key: string]: string[];
}

type InitRule = {
    type: RuleType;
    comment: string | undefined;
    example: string | undefined;
}
type InitRuleList = {
    [key: string]: InitRule[]
}
type InitList = {
    [key: string]: string[]
}
export type InitData = {
    presets: Presets;
    rules: InitRuleList;
    lists: InitList;
}

export class Auto {
    constructor(public readonly name: string, public readonly years: NumberRange) {
    }
}

class RuleStore {
    /**
     * Для каждой характеристики по ее названию
     */
    public static rules: Map<string, CompoundRule>
    /**
     * Списки допустимых значений для точного сравнения
     * По названию характеристики
     */
    public static lists: Map<string, string[]>
    /**
     * Пресеты
     * Списки характеристик для проверки
     */
    public static presets: Map<string, string[]>

    /**
     * Соотношение суб моделей к авто
     * Sub model -> [TRX -> [Ram1500,Ram2500]]
     */
    public static sub_model_to_auto: Map<keyof SubModelType, Map<string, Auto[]>> = new Map()

    /**
     * Список классов по типу характеристики
     */
    public static classes: ClassList
    /**
     * Комментарии по умолчанию для каждого типа
     */
    private static defaultComments: Map<RuleType, string>

    public static getRule(type: RuleType, comment: string = this.defaultComments.get(type) ?? '', example: string = ''): VerifyRule | undefined {
        let additional: any[] = [];
        if (startsWith(type, 'Число(') && endsWith(type, ')')) {
            // Add number of digits
            additional[0] = parseInt(type.replace('Число(', '').replace(')', ''))
            type = 'Число(?)'
        }
        if (startsWith(type, 'Диапазон чисел(') && endsWith(type, ')')) {
            // Add number of digits
            additional[0] = parseInt(type.replace('Диапазон чисел(', '').replace(')', ''))
            type = 'Диапазон чисел(?)'
        }
        const className: typeof VerifyRule | undefined = this.classes[type]
        if (className === undefined) {
            return undefined;
        }
        return new className(type, comment, example, ...additional);
    }

    public static init(data: InitData, autoListExcel: Row[]): void {
        console.log('init with', data)
        autoListExcel.shift();
        const autoList: Auto[] = [];

        createRange(0, 9).forEach((i: number): void => {
            const key: keyof SubModelType = ('Sub model' + (i === 0 ? '' : i)) as keyof SubModelType
            this.sub_model_to_auto.set(key, new Map())
        });

        autoListExcel.filter((it: Row): boolean => !isEmpty(it[0] ?? null)).forEach((row: Row): void => {
            const fullAuto: string[] = row[0].toString().split(' ')
            const years: NumberRange = NumberRange.fromString(fullAuto.shift()!)

            const auto: Auto = new Auto(fullAuto.join(' '), years)
            autoList.push(auto);

            createRange(0, 9).forEach((i: number): void => {
                // Sub model, Sub model3
                const key: keyof SubModelType = ('Sub model' + (i === 0 ? '' : i)) as keyof SubModelType

                // Raptor, TRX, ...
                const list: string[] = row[1 + i]?.toString()
                    ?.split(',')
                    ?.map((it: string): string => it.trim())
                    ?.filter((it: string): boolean => !isEmpty(it)) ?? []
                list.forEach((sub: string): void => {
                    const autos: Auto[] = this.sub_model_to_auto.get(key)!.get(sub) ?? []
                    autos.push(auto)
                    this.sub_model_to_auto.get(key)!.set(sub, autos)
                })
            })
        })

        AutoRule.autoList = new Map(Object.entries(groupBy<Auto>(autoList, (it: Auto): string => it.name)))

        this.classes = {
            'Required': RequiredRule,
            'Число': NumberRule,
            'Число(?)': NumberWithDigitsRule,
            'Дробное число': FractionalNumberRule,
            'Целое число': WholeNumberRule,
            'Диапазон чисел(?)': NumberRangeRule,
            'In List': InListRule,
            'Разболтовка': BoltPattern,
            'Строка': StringRule,
            'Is Single': IsSingleRule,
            'auto': AutoRule,
            'Sub model': SubmodelRule,
            'Размер фары': LedSize,
        }
        this.defaultComments = new Map<RuleType, string>(Object.entries({
            'Число': 'Любое число. Целое или дробное',
            'Число(?)': 'Целое число, либо число с дробной частью, в скобках указано максимальное количество знаков после запятой',
            'Дробное число': 'Только дробное число',
            'Целое число': 'Только целое число',
            'Диапазон чисел(?)': 'Диапазон чисел либо число, в скобках указано количество знаков после запятой, указать 0 для целого числа',
            'In List': 'Значение должно быть в списке',
            'Разболтовка': '4x110mm (4x4.33") - значение должно быть в файле',
            'Строка': 'Любая строка. Если Соответствие указано как "Точное" то тип будет игнорирован',
            'Required': 'Значение должно присутствовать',
            'Is Single': 'Допускается только одно значение',
            'auto': 'Авто',
            'Размер фары': '3x3", 4", 3.07x3.07"'
        }) as [RuleType, string][])

        const rules: [string, CompoundRule][] = Object.entries(data.rules).map(([param, initRules]: [string, InitRule[]]): [string, CompoundRule] => {
            function getRule(type: RuleType): VerifyRule | undefined {
                const found: InitRule | undefined = find(initRules, (it: InitRule): boolean => it.type === type)
                if (found === undefined) {
                    return undefined
                }
                return RuleStore.getRule(found.type, found.comment, found.example)
            }

            const [required, isSingle]: [VerifyRule | undefined, VerifyRule | undefined] = [getRule('Required'), getRule('Is Single')]

            const main: InitRule = last(initRules)!!
            const mainRule: VerifyRule | undefined = this.getRule(main.type, main.comment, main.example)!!
            if (mainRule === undefined) {
                alert(`Error. Unknown rule type ${main.type}`)
                console.log(`Unknown rule type ${main.type}`)
            }
            return [param, new CompoundRule(required, isSingle, mainRule!!)]
        })
        this.rules = new Map(rules)

        this.presets = new Map(Object.entries(data.presets))
        this.lists = new Map(Object.entries(data.lists))

        // @ts-ignore TODO remove
        window.rules = this.rules;
    }
}

export default RuleStore;
